import sendRequest from '../lib/sendRequest';

export const createAIQuery = async (body) => await sendRequest({
  method: 'POST',
  endpoint: 'ai-public/ask',
  body,
});

export const generateBusinessFeedback = async (accessToken, body) => await sendRequest({
  method: 'POST',
  endpoint: 'ai/business-feedback',
  accessToken,
  body,
});

export const getBusinessFeedback = async (accessToken, financialId) => await sendRequest({
  method: 'GET',
  endpoint: `ai/business-feedback/${financialId}`,
  accessToken,
});