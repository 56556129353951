import { ArrowPathIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

const ChatControls = ({ query, isDisabled, updateQuery, handleSubmitClick, refresh }) => {
  return (
    <form onSubmit={handleSubmitClick}>
      <div className='flex justify-center p-2 mt-2 border-t border-gray-300 shadow-sm item-center'>
        <input
          id='query'
          className='w-full h-12 p-2 m-2 border rounded-md bg-input-color border-input-border-color focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent'
          type='text'
          label={'Ask a question...'}
          value={query}
          autoFocus
          onChange={updateQuery} />
        <button type='submit' disabled={isDisabled}><ArrowRightIcon className='w-12 h-12 p-2 m-2 rounded-md hover:bg-gray-300' /></button>
        <button onClick={refresh}><ArrowPathIcon className='w-12 h-12 p-2 m-2 rounded-md hover:bg-gray-300' /></button>
      </div>
    </form>
  );
};

ChatControls.propTypes = {
  query: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  updateQuery: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired
};

export default ChatControls;